import { Injectable } from '@angular/core';
import type { GraphQLParams, WorkOrder } from '@tag/graphql';
import { config } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  workOrders: { items: WorkOrder[]; totalCount: number };
}

interface PlannedResponse {
  plannedWorkOrders: {
    items: WorkOrder[];
    totalCount: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersGQL extends Query<Response, GraphQLParams<'workOrder'>> {
  document = gql(config.workOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class UnassignedWorkOrdersGQL extends Query<
  Response,
  GraphQLParams<'workOrder'>
> {
  document = gql(config.workOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class OverdueWorkOrdersGQL extends Query<
  Response,
  GraphQLParams<'workOrder'>
> {
  document = gql(config.workOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class CountOverTimeGQL extends Query<
  Response,
  GraphQLParams<'workOrder'>
> {
  document = gql(config.workOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersCountGQL extends Query<
  Response & { workOrdersCount: number },
  GraphQLParams<'workOrder'>
> {
  document = gql(config.workOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class PlannedWorkOrdersGQL extends Query<
  PlannedResponse,
  GraphQLParams<'workOrder'>
> {
  document = gql(config.plannedWorkOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class PlannedWorkOrdersCountGQL extends Query<
  Response & { plannedWorkOrdersCount: number },
  GraphQLParams<'workOrder'>
> {
  document = gql(config.plannedWorkOrder.query);
}

@Injectable({
  providedIn: 'root',
})
export class AllWorkOrdersGQL extends Query<
  Response & {
    allWorkOrders: { items: WorkOrder[]; totalCount: number };
  },
  GraphQLParams<'workOrder'>
> {
  document = gql(config.allWorkOrder.query);
}
