import { Injectable } from '@angular/core';
import { cloneDeep } from '@apollo/client/utilities';
import { Apollo } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class ApolloService {
  constructor(private apollo: Apollo) {}

  /**
   * Pessimistic cache update for a single entity.
   * This method is limited to entities with an 'id' field. This is a requirement of Apollo.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param id  The id of the entity. Only works if the object field is named 'id'.
   * @example
   * pessimisticCacheUpdate('equipments', 'id');
   *
   */
  pessimisticCacheUpdate(cacheEntity: string, id: string) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record = [], { DELETE, readField }) => {
          const hasObject = (record as any).items.some((rec: { __ref: string }) => {
            console.log('readField', readField(id, rec));
            return readField(id, rec) === id;
          });
          if (hasObject) return DELETE;

          // If the record is empty, delete it.
          // This is to prevent the cache from returning an empty array. Which causes an error when adding a first item.
          if ((record as any).items.length === 0) return DELETE;

          return record;
        },
      },
    });
  }

  /**
   * Pessimistic cache update for multiple entities.
   * This method is limited to entities with an 'id' field. This is a requirement of Apollo.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param ids The ids of the entities. Only works if the object field is named 'id'.
   * @example
   * pessimisticCacheBatchUpdate('equipments', ['id1', 'id2']);
   *
   */
  pessimisticCacheBatchUpdate(cacheEntity: string, ids: string[]) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record: any = [], { DELETE, readField }) => {
          const hasObject = record.items.some((rec: { __ref: string }) => ids.some((id) => readField(id, rec) === id));
          if (hasObject) return DELETE;

          // If the record is empty, delete it.
          // This is to prevent the cache from returning an empty array. Which causes an error when adding a first item.
          if (record.items.length === 0) return DELETE;

          return record;
        },
      },
    });
  }

  /**
   * Pessimistic cache update for a single entity.
   * This method is used when you need to update the cache with a custom key.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param filter The key or keys of the entity you want to update.
   * @example
   * pessimisticCacheUpdate('equipments', 'id', 1);
   * pessimisticCacheUpdate('equipments', 'id', '1');
   * pessimisticCacheUpdate('equipments', 'id', true);
   * pessimisticCacheUpdate('equipments', 'id', null);
   * */
  pessimisticCustomCacheUpdate<T>(cacheEntity: string, filter: Partial<T> extends object ? Partial<T> : never) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record: any = [], { DELETE }) => {
          const hasObject = record.items.some((rec: T) =>
            Object.keys(filter).every((key) => {
              const k = key as keyof T;
              return rec[k] === filter[k];
            })
          );
          if (hasObject) return DELETE;

          // If the record is empty, delete it.
          // This is to prevent the cache from returning an empty array. Which causes an error when adding a first item.
          if (record.items.length === 0) return DELETE;

          return record;
        },
      },
    });
  }

  /**
   * Pessimistic cache update for multiple entities.
   * This method is used when you need to update multiple entities with the same key.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param filters The keys or keys of the entities you want to update.
   * @example
   * pessimisticCacheBatchUpdate('equipments', 'id', [1, 2]);
   * pessimisticCacheBatchUpdate('equipments', 'id', ['1', '2']);
   * pessimisticCacheBatchUpdate('equipments', 'id', [true, false]);
   * pessimisticCacheBatchUpdate('equipments', 'id', [null, null]);
   * */
  pessimisticCustomCacheBatchUpdate<T>(cacheEntity: string, filters: (Partial<T> extends object ? Partial<T> : never)[]) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record: any = [], { DELETE }) => {
          const hasObject = record.items.some((rec: T) =>
            filters.some((obj) =>
              Object.keys(obj).every((key) => {
                const k = key as keyof T;
                return rec[k] === obj[k];
              })
            )
          );
          if (hasObject) return DELETE;

          // If the record is empty, delete it.
          // This is to prevent the cache from returning an empty array. Which causes an error when adding a first item.
          if (record.items.length === 0) return DELETE;

          return record;
        },
      },
    });
  }

  /**
   * Optimistic cache update for a single entity.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param id The id of the entity.
   * @param data The data to update the entity with.
   * @example
   * optimisticCacheUpdate('equipments', 'id', { id: '1', name: 'New Name' });
   *
   * @deprecated Work in progress.
   */
  optimisticCacheUpdate<T>(cacheEntity: string, ids: (keyof T)[], data: T) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record: { items: (T & { __ref: string })[] }, { readField }) => {
          const i = record.items.findIndex((rec: { __ref: string }) => ids.every((id) => readField(id as string, rec) === data[id]));
          if (i < 0) return record;

          const newRecord = cloneDeep(record);
          newRecord.items[i] = { ...data, __ref: newRecord.items[i].__ref };
          return newRecord as any;
        },
      },
    });
  }

  /**
   * Optimistic cache update for a single entity.
   *
   * @param cacheEntity The name of the entity in the cache.
   * @param id The id of the entity.
   * @param data The data to update the entity with.
   * @example
   * optimisticCacheUpdate('equipments', 'id', { id: '1', name: 'New Name' });
   *
   * @deprecated Work in progress.
   */
  optimisticCacheDelete<T>(cacheEntity: string, ids: (keyof T)[], data: T) {
    this.apollo.client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        [cacheEntity]: (record: { items: (T & { __ref: string })[] }, { readField }) => {
          const i = record.items.findIndex((rec: { __ref: string }) => ids.every((id) => readField(id as string, rec) === data[id]));
          if (i < 0) return record;

          const newRecord = cloneDeep(record);
          newRecord.items.splice(i, 1);

          return newRecord as any;
        },
      },
    });
  }
}
