import { Operation } from 'fast-json-patch';

import { FeedbackStateObject } from '@stores-states/feedback.state';

/**
 * Add Feedback
 *
 * @param payload Feedback object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class AddFeedback {
  static readonly type = '[Feedback] Add';

  constructor(public payload: any) {}
}

/**
 * Get all Feedbacks By Document
 *
 * @param documentNo Document Document No
 * @param type Feedback state object document Type. 'feedback' | 'comment' | 'both'
 * @param documentLineNo Document Document line no if any or 0.
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFeedbacksByDocument {
  static readonly type = '[Feedback] Get By Document';

  constructor(
    public documentNo: string,
    public type: 'feedback' | 'comment' | 'both',
    public documentLineNo?: number
  ) {}
}

/**
 * Get all Feedbacks By Equipment
 *
 * @param equipmentId Document Equipment ID
 * @param type Feedback state object document Type. 'feedback' | 'comment' | 'both'
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFeedbacksByEquipment {
  static readonly type = '[Feedback] Get By Equipment';

  constructor(
    public equipmentId: string,
    public type: 'feedback' | 'comment' | 'both'
  ) {}
}

/**
 * Get a single Feedback
 *
 * @todo Important this is not working for comments and can only be used with Feedback.
 * @param tableName Table name  (Order, OrderLine, FinishedOrder, FinishedLine)
 * @param documentType Source Document Type (Planned, Released, Finished)
 * @param documentNo Source Document line no if any or 0.
 * @param documentLineNo Feedback unique ID
 * @param feedbackType Feedback Type (General, As Found, Repairs, As Left, Recommendations)
 * @param lineNo Feedback unique ID
 */
export class GetFeedback {
  static readonly type = '[Feedback] GetSingle';

  constructor(
    public tableName: 'Order' | 'OrderLine' | 'FinishedOrder' | 'FinishedLine',
    public documentType: 'Planned' | 'Released' | 'Finished',
    public documentNo: string,
    public documentLineNo: number,
    public feedbackType:
      | 'General'
      | 'As Found'
      | 'Repairs'
      | 'As Left'
      | 'Recommendations',
    public lineNo: number
  ) {}
}

/**
 * Update Feedback, this method will dynamically update the cache Feedback list and selected Feedback.
 *
 * @param id The store generated identifier.
 * @param patch JSON patch object used to update the Feedback. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateFeedback {
  static readonly type = '[Feedback] Update';

  constructor(public id: string, public patch: Operation[]) {}
}

/**
 * Delete Feedback, this method will dynamically update the cache Feedback list and set selected Feedback to null.
 *
 * @param id The store generated identifier.
 */
export class DeleteFeedback {
  static readonly type = '[Feedback] Delete';

  constructor(public id: string) {}
}

/**
 * Remove Feedback , this method will only remove feedback from local chache without communication with remote server.
 *
 * @param workOrderNo  Work Order No.
 * @param LineNo Line No.
 */
export class RemoveCachedFeedbacks {
  static readonly type = '[Feedback] Remove Cached';

  constructor(public workOrderNo: string, public lineNo?: number) {}
}
