export enum DocStorDocumentType {
  Equipment = 'Equipment',
  Personnel = 'Personnel',
  FinishedWorkOrder = 'FinishedWorkOrder',
  FinishedWorkOrderLine = 'FinishedWorkOrderLine',
  PlannedWorkOrder = 'PlannedWorkOrder',
  PlannedWorkOrderLine = 'PlannedWorkOrderLine',
  PurchaseOrder = 'PurchaseOrder',
  Request = 'Request',
  WorkOrder = 'WorkOrder',
  WorkOrderLine = 'WorkOrderLine',
}

export interface DocStorEntity {
  spUrl: string;
  apiDetail: string;
}

export interface DocStorEntityDetail {
  apiUrl: string;
  method: string;
  application: string;
  apiKey: string;
  apiKeyValue: string;
  path: string;
  filter: string;
}

export interface DocStorUploadEntity {
  apiUrl: string;
  apiKeyValue: string;
  path: string;
  name: string;
  metadata: any;
  response: string;
}

export interface DocstashFileReturn {
  __metadata: Metadata;
  FirstUniqueAncestorSecurableObject: FirstUniqueAncestorSecurableObject;
  RoleAssignments: RoleAssignments;
  AttachmentFiles: AttachmentFiles;
  ContentType: ContentType;
  GetDlpPolicyTip: GetDlpPolicyTip;
  FieldValuesAsHtml: FieldValuesAsHtml;
  FieldValuesAsText: FieldValuesAsText;
  FieldValuesForEdit: FieldValuesForEdit;
  File: File;
  Folder: Folder;
  LikedByInformation: LikedByInformation;
  ParentList: ParentList;
  Properties: Properties;
  Versions: Versions;
  FileSystemObjectType: number;
  Id: number;
  ServerRedirectedEmbedUri: any;
  ServerRedirectedEmbedUrl: string;
  ContentTypeId: string;
  OData__ColorTag: any;
  ComplianceAssetId: any;
  Title: string | null;
  Document_Type: any;
  No: any;
  Vendor_Invoice_No: any;
  Document_Date: any;
  Vendor: any;
  Vendor_No: any;
  ID: number;
  Created: string;
  AuthorId: number;
  Modified: string;
  EditorId: number;
  OData__CopySource: any;
  CheckoutUserId: any;
  OData__UIVersionString: string;
  GUID: string;
}

interface Metadata {
  id: string;
  uri: string;
  etag: string;
  type: string;
}

interface FirstUniqueAncestorSecurableObject {
  __deferred: Deferred;
}

interface Deferred {
  uri: string;
}

interface RoleAssignments {
  __deferred: Deferred2;
}

interface Deferred2 {
  uri: string;
}

interface AttachmentFiles {
  __deferred: Deferred3;
}

interface Deferred3 {
  uri: string;
}

interface ContentType {
  __deferred: Deferred4;
}

interface Deferred4 {
  uri: string;
}

interface GetDlpPolicyTip {
  __deferred: Deferred5;
}

interface Deferred5 {
  uri: string;
}

interface FieldValuesAsHtml {
  __deferred: Deferred6;
}

interface Deferred6 {
  uri: string;
}

interface FieldValuesAsText {
  __deferred: Deferred7;
}

interface Deferred7 {
  uri: string;
}

interface FieldValuesForEdit {
  __deferred: Deferred8;
}

interface Deferred8 {
  uri: string;
}

interface File {
  __deferred: Deferred9;
}

interface Deferred9 {
  uri: string;
}

interface Folder {
  __deferred: Deferred10;
}

interface Deferred10 {
  uri: string;
}

interface LikedByInformation {
  __deferred: Deferred11;
}

interface Deferred11 {
  uri: string;
}

interface ParentList {
  __deferred: Deferred12;
}

interface Deferred12 {
  uri: string;
}

interface Properties {
  __deferred: Deferred13;
}

interface Deferred13 {
  uri: string;
}

interface Versions {
  __deferred: Deferred14;
}

interface Deferred14 {
  uri: string;
}
